import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  DetailMenu,
  RequestMenuCreate,
  ResponseListMenu,
} from "@/models/interface/menu.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class MenuService extends HttpClient {
  constructor() {
    super();
  }

  createMenu(payload: RequestMenuCreate): Promise<boolean> {
    return this.post<boolean, RequestMenuCreate>(Api.Menus, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateMenu(payload: RequestMenuCreate, id: string): Promise<boolean> {
    return this.put<boolean, RequestMenuCreate>(`${Api.Menus}/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  findMenuDetail(id: string): Promise<DetailMenu> {
    return this.get<DetailMenu>(`${Api.Menus}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listMenus(params: RequestQueryParamsModel): Promise<ResponseListMenu> {
    return this.get<ResponseListMenu>(Api.Menus, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const menuService = new MenuService();
